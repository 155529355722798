import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Container, Nav, NavItem } from "react-bootstrap";
import { fetchUserPermission } from "../../components/SecurityComponent/SecurityComponent";
import headerLogo from "../../../src/assets/images/hmg-logo.png";
import { loginRequest, tokenRequest } from "../../authConfig";
import logMessage from "../../../src/utils/logger";
import { LOG_MESSAGE } from "../../config/app.constant";
import { fetchData, patchData } from "../../api/apiService";

const Header = () => {
  const [permissions, setPermissions] = useState([]);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(null);
  const popoverRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [logoutClicked, setLogoutClicked] = useState(false);
  // eslint-disable-next-line
  const tableauRef = useRef(null);
  const lastActivityTime = useRef(Date.now());
  const [sessionTimeout, setSessionTimeout] = useState(0); // Session timeout in seconds

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      tokenRequest.account = account;

      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          if (response !== null) {
            logMessage("info", LOG_MESSAGE.USER_LOGGED_IN, {
              userData: response,
            });
            setAccessToken(response.accessToken);
          }
        })
        .catch(console.error);
    } else {
      localStorage.clear();
    }
  }, [accounts, instance]);

  useEffect(() => {
    if (accessToken && !localStorage.getItem("accessToken")) {
      if (
        localStorage.getItem("msal.account.keys") &&
        localStorage.getItem("msal.account.keys") !== "[]"
      ) {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("user_error_count", 0);
        if (location.pathname === "/") {
          navigate("/agreement");
        }
      } else {
        localStorage.clear();
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      }
    }
    if (accessToken) {
      const fetchData = async () => {
        try {
          const userPermissions = await fetchUserPermission();
          if (userPermissions.dev_security_label.length === 0) {
            setPermissions(["nav_logout"]);
          } else {
            setPermissions(userPermissions.dev_security_label);
          }
          setPermissionsLoaded(true);
          setUserLoggedIn(true);
        } catch (error) {
          if (error.statusCode === 401) {
            setUserLoggedIn(false);
          }
          if (error.statusCode === 403) {
            setPermissions(["nav_logout"]);
            navigate("/403");
          }
        }
      };

      fetchData();
    }
  }, [accessToken, location.pathname, navigate]);

  useEffect(() => {
    let interval = null;

    const fetchDataAndInitializeCounter = async () => {
      try {
        const appConfigurationResponse = await fetchData(
          "/application-configuration?key=session_timeout"
        );
        const appConfiguration =
          appConfigurationResponse.application_configurations.find(
            (item) => item.name === "session_timeout"
          );

        if (appConfiguration) {
          const sessionTimeoutInMinutes = parseInt(appConfiguration.value, 10);
          const sessionTimeoutInSeconds = sessionTimeoutInMinutes * 60;
          setSessionTimeout(sessionTimeoutInSeconds);
        }
      } catch (error) {
        console.error("Error fetching session timeout configuration:", error);
      }
    };

    if (userLoggedIn) {
      fetchDataAndInitializeCounter();
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [userLoggedIn]);

  const handleLogout = useCallback(async () => {
    logMessage("info", LOG_MESSAGE.USER_LOGGED_OUT, {});
    setLogoutClicked(true);  
    const user = localStorage.getItem("user_id");
    try {
      await patchData(`/users/${user}/clearPVPReview`, {});
      await patchData(`/users/${user}/clearDocumentReview`, {});
      localStorage.clear();
      instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    } catch (error) {
      localStorage.clear();
      instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      console.error("Error during logout API call:", error);
    }
  }, [instance]);
  
  useEffect(() => {
    let timeoutId;

    const checkTimeout = () => {
      const elapsed = (Date.now() - lastActivityTime.current) / 1000;
      if (elapsed >= sessionTimeout) {
        handleLogout();
      } else {
        timeoutId = setTimeout(checkTimeout, 1000);
      }
    };

    if (userLoggedIn) {
      timeoutId = setTimeout(checkTimeout, 1000);
    } else {
      clearTimeout(timeoutId);
    }

    return () => clearTimeout(timeoutId);
  }, [sessionTimeout, userLoggedIn, handleLogout]);

  const resetTimeout = () => {
    lastActivityTime.current = Date.now();
  };

  const loginUser = () => {
    instance.loginRedirect(loginRequest);
  };

  useEffect(() => {
    const handleActivity = () => {
      // console.log("reset");
      resetTimeout();
    };

    const handleIframeMessage = (event) => {
      // if (event.data === "user-activity") {
      resetTimeout();
      // }
    };

    const setupTableauEventListeners = () => {
      const tableauViz = tableauRef.current;

      if (tableauViz) {
        tableauViz.addEventListener("marksselection", handleActivity);
        tableauViz.addEventListener("filterchange", handleActivity);
        tableauViz.addEventListener("parametervaluechange", handleActivity);
        tableauViz.addEventListener("customviewload", handleActivity);
        tableauViz.addEventListener("customviewsave", handleActivity);
        tableauViz.addEventListener("tabchange", handleActivity);
      }
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("message", handleIframeMessage);

    setupTableauEventListeners();

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("message", handleIframeMessage);

      // eslint-disable-next-line
      const tableauViz = tableauRef.current;
      if (tableauViz) {
        tableauViz.removeEventListener("marksselection", handleActivity);
        tableauViz.removeEventListener("filterchange", handleActivity);
        tableauViz.removeEventListener("parametervaluechange", handleActivity);
        tableauViz.removeEventListener("customviewload", handleActivity);
        tableauViz.removeEventListener("customviewsave", handleActivity);
        tableauViz.removeEventListener("tabchange", handleActivity);
      }
    };
  }, []);

  const popover = (
    <Popover
      id="popover-secondary"
      className="mt-2"
      style={{ maxWidth: "none" }}
      ref={popoverRef}
    >
      <Popover.Body>
        <div style={{ width: "300px" }}>
          <p>
            <strong>Name:</strong> {localStorage.getItem("user_name")}
          </p>
          <p>
            <strong>Email:</strong> {localStorage.getItem("user_email")}
          </p>
        </div>
      </Popover.Body>
    </Popover>
  );

  if (userLoggedIn === false) {
    if (
      location.pathname !== "/" &&
      location.pathname !== "/contact" &&
      location.pathname !== "/health"
    ) {
      return <Navigate to="/" replace />;
    }
  }

  if (!permissionsLoaded) {
    return (
      <>
        <Nav className="navbar navbar-expand-lg navbar-light shadow-sm sticky-top">
          <Container className="container px-3 px-sm-0">
            <Link to="/" className="navbar-brand text-white">
              <Image src={headerLogo} className="header-logo" />
            </Link>
            <Button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </Button>
            <Container
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <Nav className="navbar-nav ms-auto mb-2 mb-lg-0">
                <Nav.Item className="nav-item">
                  <Link
                    to="https://honestmedicalgroup.com/news-insights/"
                    target="_blank"
                    className="nav-link nav-inactive underline hide"
                  >
                    News
                  </Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Link
                    to="/contact"
                    className={
                      location.pathname === "/contact"
                        ? "nav-link nav-active underline hide"
                        : "nav-link nav-inactive underline hide"
                    }
                  >
                    Contact
                  </Link>
                </Nav.Item>
                <UnauthenticatedTemplate>
                  <Nav.Item className="nav-item">
                    <Link
                      className="nav-link nav-inactive underline"
                      onClick={loginUser}
                    >
                      Login
                    </Link>
                  </Nav.Item>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  {permissions?.includes("nav_carehub") && (
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={popover}
                    >
                      <NavItem className="nav-item">
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={popover}
                        >
                          <Link className="nav-link nav-inactive underline">
                            Profile
                          </Link>
                        </OverlayTrigger>
                      </NavItem>
                    </OverlayTrigger>
                  )}
                  {(permissions?.includes("nav_carehub") ||
                    permissions?.includes("nav_logout")) && (
                    <Link
                      onClick={logoutClicked ? null : handleLogout}
                      className={`nav-link  ${
                        logoutClicked ? "nav-inactive " : ""
                      }`}
                      disabled={logoutClicked}
                    >
                      {logoutClicked ? "Logging Out..." : "Logout"}
                    </Link>
                  )}
                </AuthenticatedTemplate>
              </Nav>
            </Container>
          </Container>
        </Nav>
      </>
    );
  }

  return (
    <>
      <Nav className="navbar navbar-expand-lg navbar-light shadow-sm sticky-top">
        <Container className="container px-3 px-sm-0">
          {permissions?.includes("home_insight") ? (
            <Link to="/insights" className="navbar-brand text-white">
              <Image src={headerLogo} className="header-logo" />
            </Link>
          ) : permissions?.includes("nav_pvp") ? (
            <Link to="/pvp" className="navbar-brand text-white">
              <Image src={headerLogo} className="header-logo" />
            </Link>
          ) : (
            <Link to="/" className="navbar-brand text-white">
              <Image src={headerLogo} className="header-logo" />
            </Link>
          )}

          <Button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </Button>
          <Container
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            <Nav className="navbar-nav ms-auto mb-2 mb-lg-0">
              {permissions?.includes("nav_insights") && (
                <Nav.Item className="nav-item underline">
                  <Link
                    to="/insights"
                    className={
                      location.pathname.includes("insight")
                        ? "nav-link nav-active"
                        : "nav-link nav-inactive"
                    }
                  >
                    Insights
                  </Link>
                </Nav.Item>
              )}
              {permissions?.includes("nav_administration") && (
                <Nav.Item className="nav-item ">
                  <Link
                    to="/administration"
                    className={
                      location.pathname === "/administration" ||
                      location.pathname === "/application_configuration" ||
                      location.pathname === "/user_management" ||
                      location.pathname === "/request_details"
                        ? "nav-link nav-active underline"
                        : "nav-link nav-inactive underline"
                    }
                  >
                    Administration
                  </Link>
                </Nav.Item>
              )}
              {permissions?.includes("nav_role_management") && (
                <Nav.Item className="nav-item">
                  <Link
                    to="/role_management"
                    className={
                      location.pathname === "/role_management"
                        ? "nav-link nav-active underline"
                        : "nav-link nav-inactive underline"
                    }
                  >
                    Role Management
                  </Link>
                </Nav.Item>
              )}
              {permissions?.includes("nav_pvp") && (
                <Nav.Item className="nav-item underline">
                  <Link
                    to="/pvp"
                    className={
                      location.pathname.includes("pvp")
                        ? "nav-link nav-active"
                        : "nav-link nav-inactive"
                    }
                  >
                    PVP
                  </Link>
                </Nav.Item>
              )}
              <Nav.Item className="nav-item">
                <Link
                  to="https://honestmedicalgroup.com/news-insights/"
                  target="_blank"
                  className="nav-link nav-inactive  underline hide"
                >
                  News
                </Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Link
                  to="/contact"
                  className={
                    location.pathname === "/contact"
                      ? "nav-link nav-active underline hide"
                      : "nav-link nav-inactive underline hide"
                  }
                >
                  Contact
                </Link>
              </Nav.Item>
              {permissions?.includes("nav_carehub") && (
                <NavItem className="nav-item">
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popover}
                  >
                    <Link className="nav-link nav-inactive underline">
                      Profile
                    </Link>
                  </OverlayTrigger>
                </NavItem>
              )}
              {(permissions?.includes("nav_carehub") ||
                permissions?.includes("nav_logout")) && (
                <NavItem className="nav-item">
                  <Link
                    onClick={logoutClicked ? null : handleLogout}
                    className={`nav-link  ${
                      logoutClicked ? "nav-inactive" : "underline"
                    }`}
                    disabled={logoutClicked}
                  >
                    {logoutClicked ? "Logging Out..." : "Logout"}
                  </Link>
                </NavItem>
              )}
            </Nav>
          </Container>
        </Container>
      </Nav>
    </>
  );
};

export default Header;
